/*
 * メニューの開閉でnavi全体を変更するためにexpandedクラスを動的に設定
 */
["Profile", "Product", "Recruit"].forEach((value) => {
  $("#collapse" + value).on("show.bs.collapse", function () {
    $("#heading" + value).addClass("expanded");
  });
  $("#collapse" + value).on("hide.bs.collapse", function () {
    $("#heading" + value).removeClass("expanded");
  });
});
