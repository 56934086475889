/*
 * メニューの開閉でnavi全体を変更するためにexpandedクラスを動的に設定
 */
$("#navmenu").on("show.bs.collapse", function () {
  $(".navbar").addClass("expanded");
  $(".navbar-toggler").addClass("expanded");
});
$("#navmenu").on("hide.bs.collapse", function () {
  $(".navbar").removeClass("expanded");
  $(".navbar-toggler").removeClass("expanded");
});
